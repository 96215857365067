.card {
  border-radius: 1px;
  border: 1px solid #ebebeb;
}

.card-3d {
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.22),
    0 1px 2px 0 rgba(0, 0, 0, 0.12),
    0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

.card-3d-light {
  box-shadow:
    0 1px 1px 0 rgba(60,75,100,.14),
    0 2px 1px -1px rgba(60,75,100,.12),
    0 1px 3px 0 rgba(60,75,100,.2);
}
.card-3d-light:hover{
  box-shadow:
    0 1px 1px 1px rgba(50,120,175,.14),
    0 2px 1px 0px rgba(50,120,175,.12),
    0 1px 3px 2px rgba(50,120,175,.2);
}

.card-3d-nb {
  border: 0;
  box-shadow:
    0 1px 1px 0 rgba(60,75,100,.14),
    0 2px 1px -1px rgba(60,75,100,.12),
    0 1px 3px 0 rgba(60,75,100,.2);
}

.viz { 
  height: calc(100vh - 187px);
}

.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}

.warning-line {
  color: #e78608 !important;
}