.spinner-absolute {
  position: absolute;
  top: 45%;
  left: 50%;
  text-align: center;
  z-index: 1000;
  transform: translate(-50%, -50%);
}

.spinner-relative {
  position: relative;
  text-align: center;
  margin: auto;
  height: inherit;
  z-index: 1000;
}