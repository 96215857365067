// Alert
.alert {
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.5rem;

  &.alert-dismissible {
    .close {
      padding: 0.45rem 0.5rem;
    }
  }
}

.alert-warning {
  background-color: #FFF3CD;
  color: #856404;
  border-color: #FFEEBA;

  .btn-warning {
    background-color: #FAD970;
    color: #856404 !important;
    border-color: #ffeeba !important;
  }

  .text-warning {
    color: #856404 !important;
  }
}

.alert-danger {


  .btn-danger {
    background-color: #814237;
    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background-color: #75291c;
    }
    border-color: #ffc0ba;
  }

}
