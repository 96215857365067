// Jumbotron
.jumbotron {
    border-radius: 0;
    color: $white;
    padding: 1.5rem;
    text-align: center;
    &.gradient-primary {
        background: transparent linear-gradient(98deg, #ED1B24 0%, #B20F0F 100%) 0% 0% no-repeat padding-box;
    }

    .jumbotron-title {
        font-family: "Open Sans", sans-serif;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 1.5;
    }

    .lead {
        font-size: 0.75rem;
    }

    .filter-search {
        margin: 24px auto 17px;
    }

}

.title-2 {
    color: var(--dark-grey);
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 1.125rem;
    text-align: center;
}



.topics-wrap {
    max-width: 990px;
    margin: 40px auto;
}

.topics {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 15px #3030301A;
    border-radius: 6px;
    padding: 18px 33px;
    min-height: 90%;

    .topics-header {
        font-size: 0.875rem;
        font-weight: 600;
        color:var(--primary);
        margin-bottom: 15px;
        text-align: center;
    }

    .topic-link {
        color: var(--dark-grey);  
        .c-icon {
            color: #6C7D8B;
            height: 1.125rem;
            width: 1.125rem;
            margin-right: 8px;
        }    
    }
}

.manuals {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 15px #3030301A;
    border-radius: 6px;
    margin: 0 auto;
    padding: 20px 0 10px;
    max-width: 480px;

    .title-3 {
        color: var(--dark-grey);
        font-family: "Open Sans", sans-serif;
        font-weight: normal;
        font-size: 0.938rem;
        text-align: center;

        .c-icon {
            color: var(--primary);
            height: 26px;
            width:26px;
         }
    }
}




