// Tooltips
.tippy-content {
    background-color:inherit;
    color: inherit;
    font-family: "Open Sans", var(--font-family-sans-serif);
    font-size: 0.75rem;
}

.tooltip-link {
    color: #fff !important;
    white-space: nowrap;
    font-weight: 600;
    
    &:hover {
        color: #fff !important;
        text-decoration: none !important;
    }
}

// Tooltip for version
.version-tooltip {
    .tippy-arrow {
        left: -85px !important;
    }
    .nowrap {
        white-space: nowrap;
    }
    .tippy-box[data-theme~=no-border-radius]>.tippy-content {
        max-width: none !important;
        min-width: 245px;
    }
}

// Tooltip for Sidebar Nav
.c-sidebar-nav {

    .tippy-arrow {
        display: block !important;
        &:before {
            border-right-color: var(--primary) !important;
            }
        }
       
    .tippy-box {
        left: -5px;
        background-color: var(--primary) !important;
        border-radius: 4px !important;
    }
    
    .tippy-tooltip {
        background-color: var(--primary) !important;
        border-radius: 4px !important;
    }
    
    .tippy-content {
        background-color: var(--primary) !important;
        border-color: var(--primary) !important;
        border-radius: 4px !important;
        box-shadow: none!important;
    }

    .tippy-backdrop {
        background-color:  var(--primary) !important;
    }

    .tippy-svg-arrow {
        fill: var(--primary) !important;
    }

    .nowrap {
        white-space: nowrap;
    }

    .wrap { 
        white-space: pre-wrap;      /* CSS3 */   
        white-space: -moz-pre-wrap; /* Firefox */    
        white-space: -pre-wrap;     /* Opera <7 */   
        white-space: -o-pre-wrap;   /* Opera 7 */    
        word-wrap: break-word;      /* IE */
    }
}

// No border radius theme
.tippy-box[data-theme~=no-border-radius] {
    background-color: #fff;
    background-clip: padding-box;
    border-color: #d8dbe0;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.30);
    color: var(--darker-grey);
    border-radius: 0;
}

.tippy-box[data-theme~=no-border-radius]>.tippy-tooltip {
    background-color:#ffffff;
    border-radius: 0;
}

.tippy-box[data-theme~=no-border-radius]>.tippy-content {
    border-radius: 0;
    text-align: left;
    max-width: 280px;
}

.tippy-box[data-theme~=no-border-radius]>.tippy-backdrop {
    background-color: #fff
}

.tippy-box[data-theme~=no-border-radius]>.tippy-arrow:after,
.tippy-box[data-theme~=no-border-radius]>.tippy-svg-arrow:after {
    content: "";
    position: absolute;
    z-index: -1
}

.tippy-box[data-theme~=no-border-radius]>.tippy-arrow:after {
    border-color: transparent;
    border-style: solid;
}

.tippy-box[data-theme~=no-border-radius][data-placement^=top]>.tippy-arrow:before {
    border-top-color: #fff
}

.tippy-box[data-theme~=no-border-radius][data-placement^=top]>.tippy-arrow:after {
    border-top-color: #d8dbe0;
    border-width: 7px 7px 0;
    top: 17px;
    left: 1px
}

.tippy-box[data-theme~=no-border-radius][data-placement^=top]>.tippy-svg-arrow>svg {
    top: 16px
}

.tippy-box[data-theme~=no-border-radius][data-placement^=top]>.tippy-svg-arrow:after {
    top: 17px
}

.tippy-box[data-theme~=no-border-radius][data-placement^=bottom]>.tippy-arrow:before {
    border-bottom-color: #fff;
    bottom: 16px;
}

.tippy-box[data-theme~=no-border-radius][data-placement^=bottom]>.tippy-arrow:after {
    border-bottom-color: #d8dbe0;
    border-width: 0 7px 7px;
    bottom: 17px;
    left: 1px
}

.tippy-box[data-theme~=no-border-radius][data-placement^=bottom]>.tippy-svg-arrow>svg {
    bottom: 16px
}

.tippy-box[data-theme~=no-border-radius][data-placement^=bottom]>.tippy-svg-arrow:after {
    bottom: 17px
}

.tippy-box[data-theme~=no-border-radius][data-placement^=left]>.tippy-arrow:before {
    border-left-color: #fff
}

.tippy-box[data-theme~=no-border-radius][data-placement^=left]>.tippy-arrow:after {
    border-left-color: #d8dbe0;
    border-width: 7px 0 7px 7px;
    left: 17px;
    top: 1px
}

.tippy-box[data-theme~=no-border-radius][data-placement^=left]>.tippy-svg-arrow>svg {
    left: 11px
}

.tippy-box[data-theme~=no-border-radius][data-placement^=left]>.tippy-svg-arrow:after {
    left: 12px
}

.tippy-box[data-theme~=no-border-radius][data-placement^=right]>.tippy-arrow:before {
    border-right-color: #fff;
    right: 16px
}

.tippy-box[data-theme~=no-border-radius][data-placement^=right]>.tippy-arrow:after {
    border-width: 7px 7px 7px 0;
    right: 17px;
    top: 1px;
    border-right-color: #d8dbe0;
}

.tippy-box[data-theme~=no-border-radius][data-placement^=right]>.tippy-svg-arrow>svg {
    right: 11px
}

.tippy-box[data-theme~=no-border-radius][data-placement^=right]>.tippy-svg-arrow:after {
    right: 12px
}

.tippy-box[data-theme~=no-border-radius]>.tippy-svg-arrow {
    fill: #fff
}

.tippy-box[data-theme~=no-border-radius]>.tippy-svg-arrow:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA2czEuNzk2LS4wMTMgNC42Ny0zLjYxNUM1Ljg1MS45IDYuOTMuMDA2IDggMGMxLjA3LS4wMDYgMi4xNDguODg3IDMuMzQzIDIuMzg1QzE0LjIzMyA2LjAwNSAxNiA2IDE2IDZIMHoiIGZpbGw9InJnYmEoMCwgOCwgMTYsIDAuMikiLz48L3N2Zz4=);
    background-size: 16px 6px;
    width: 16px;
    height: 6px
}

// Dark border theme
.tippy-box[data-theme~=dark-border] {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid  #d8dbe0;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.30);
    color: var(--darker-grey);
}

.tippy-box[data-theme~=dark-border]>.tippy-content {
    max-width: none;
    padding:12px;
}

.tippy-box[data-theme~=dark-border]>.tippy-backdrop {
    background-color: #fff
}

.tippy-box[data-theme~=dark-border]>.tippy-arrow:after,
.tippy-box[data-theme~=dark-border]>.tippy-svg-arrow:after {
    content: "";
    position: absolute;
    z-index: -1
}

.tippy-box[data-theme~=dark-border]>.tippy-arrow:after {
    border-color: transparent;
    border-style: solid
}

.tippy-box[data-theme~=dark-border][data-placement^=top]>.tippy-arrow:before {
    border-top-color: #fff
}

.tippy-box[data-theme~=dark-border][data-placement^=top]>.tippy-arrow:after {
    border-top-color: #d8dbe0;
    border-width: 7px 7px 0;
    top: 17px;
    left: 1px
}

.tippy-box[data-theme~=dark-border][data-placement^=top]>.tippy-svg-arrow>svg {
    top: 16px
}

.tippy-box[data-theme~=dark-border][data-placement^=top]>.tippy-svg-arrow:after {
    top: 17px
}

.tippy-box[data-theme~=dark-border][data-placement^=bottom]>.tippy-arrow:before {
    border-bottom-color: #fff;
    bottom: 16px
}

.tippy-box[data-theme~=dark-border][data-placement^=bottom]>.tippy-arrow:after {
    border-bottom-color: #d8dbe0;
    border-width: 0 7px 7px;
    bottom: 17px;
    left: 1px
}

.tippy-box[data-theme~=dark-border][data-placement^=bottom]>.tippy-svg-arrow>svg {
    bottom: 16px
}

.tippy-box[data-theme~=dark-border][data-placement^=bottom]>.tippy-svg-arrow:after {
    bottom: 17px
}

.tippy-box[data-theme~=dark-border][data-placement^=left]>.tippy-arrow:before {
    border-left-color: #fff
}

.tippy-box[data-theme~=dark-border][data-placement^=left]>.tippy-arrow:after {
    border-left-color: #d8dbe0;
    border-width: 7px 0 7px 7px;
    left: 17px;
    top: 1px
}

.tippy-box[data-theme~=dark-border][data-placement^=left]>.tippy-svg-arrow>svg {
    left: 11px
}

.tippy-box[data-theme~=dark-border][data-placement^=left]>.tippy-svg-arrow:after {
    left: 12px
}

.tippy-box[data-theme~=dark-border][data-placement^=right]>.tippy-arrow:before {
    border-right-color: #fff;
    right: 16px
}

.tippy-box[data-theme~=dark-border][data-placement^=right]>.tippy-arrow:after {
    border-width: 7px 7px 7px 0;
    right: 17px;
    top: 1px;
    border-right-color: #d8dbe0;
}

.tippy-box[data-theme~=dark-border][data-placement^=right]>.tippy-svg-arrow>svg {
    right: 11px
}

.tippy-box[data-theme~=dark-border][data-placement^=right]>.tippy-svg-arrow:after {
    right: 12px
}

.tippy-box[data-theme~=dark-border]>.tippy-svg-arrow {
    fill: #fff
}

.tippy-box[data-theme~=dark-border]>.tippy-svg-arrow:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA2czEuNzk2LS4wMTMgNC42Ny0zLjYxNUM1Ljg1MS45IDYuOTMuMDA2IDggMGMxLjA3LS4wMDYgMi4xNDguODg3IDMuMzQzIDIuMzg1QzE0LjIzMyA2LjAwNSAxNiA2IDE2IDZIMHoiIGZpbGw9InJnYmEoMCwgOCwgMTYsIDAuMikiLz48L3N2Zz4=);
    background-size: 16px 6px;
    width: 16px;
    height: 6px
}

