// Forms

.form-control-wrapp {
  display: flex;
  background-color: #F7F8FA;
  border-radius: 0.25rem;
  .form-control {
    margin: 0;
    padding: 0.5rem;
  }
}

.form-control {
  border-radius: 4px;
  border: 1px solid #DEDBDB;
  padding: 0.375rem 0.5rem;
  color: var(--darker-grey);
  &:focus {
    color: var(--darker-grey);
    border-color: #DEDBDB;
    box-shadow: none;
  }
}

.external-link {
  display: block;
  margin-top: 0.85rem;
}

.form-control:not(select) {
  background-color: #F7F8FA;
   &:focus {
    background-color: #F7F8FA;
    color: var(--darker-grey);
    border-color: #DEDBDB;
  }
}

.bg-input {
  background-color: #F7F8FA;
}

.form-control:disabled {
  background-color: #D9DBE2;
  border-radius: 0.125rem;
  &:focus {
    background-color: #D9DBE2;
  }
}

.form-control[readonly] {
  background-color: #F7F8FA;
   &:focus {
    background-color: #F7F8FA;
    color: var(--darker-grey);
    border-color: #DEDBDB;
  }
}

.form-control:not(textarea) {
  height: 30px;
}

// prepopulated form browser color fix FF
input {
  filter: none;
}

// prepopulated form browser color fix Chrome
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #DEDBDB;
  -webkit-text-fill-color: var(--darker-grey);
  -webkit-box-shadow: 0 0 0px 1000px#F7F8FA inset;
  box-shadow: 0 0 0px 1000px#F7F8FA inset;
  transition: background-color 5000s ease-in-out 0s;
  font-family: "Open Sans", Verdana, Geneva, Tahoma, sans-serif;
}

.table-filter-wrap .form-control,
.filter-search .form-control {
  background-color: #ffffff;
  &:focus {
    background-color: #ffffff;
  }
}

.custom-select,
.form-control {
  &:focus {
 //   box-shadow: 0 0 0 0.2rem rgba(81, 65, 224, 0.5);
    border-color: #DEDBDB;
    box-shadow: none;
  }
}

.form-group div,
.form-label,
.form-inline label,
.form-control,
.form-group label,
.input-group label,
.input-group-text,
.form-element,
.custom-control-inline {
  color: var(--darker-grey);
  &.invalid-feedback {
    color: var(--danger);
  }
}

.custom-checkbox {
  .custom-control-label {
    &::before {
      border: 2px solid #DEDBDB;
      border-radius: 0;
    }
  }
}

.custom-control-inline {
  .custom-control-label {
    width: 100%;
    .selected-input-token {
      font-size: 0.563rem;
      font-weight: 600;
      text-transform: uppercase;
      float: right;
    }
  }
}

.chart-separator {
  border: 0 none;
  height: 1px;
  background:#DDD;
  background-image: linear-gradient(to right, #eee, #DDD, #eee);
  box-shadow: 0 3px 4px 0 rgba(60, 75, 100, 0.14);
}

.form-title {
  color: var(--darker-grey);
  text-transform: uppercase;
  font-weight: bold;
}

.form-subtitle {
  font-weight: 600;
  color: #576672;
}

.note-subtitle {
  font-size: 0.75rem;
  color: #797979;
}

.note-text {
  font-size: 0.75rem;
  color: var(--darker-grey);
}

.form-confirmation {
  text-align: left;
  margin: 0.3rem 0 0 0.1rem;
}

.form-actions {
  margin-top: 15px;
  .btn-light {
    border-color: #AAA1A1;
  }
}

.custom-select {
  background-color: #F7F8FA;
  color: var(--darker-grey);
  font-size: 0.8rem;
  height: 30px;
  border: 1px solid #dedbdb;
  padding: 0.35em 1.75rem 0.35rem 0.35rem;
  &:disabled {
    background-color: #D9DBE2;
    color: var(--darker-grey);
  }
}

.table-expand-toggle {
  -moz-transition: transform 1s;
  -webkit-transition: transform 1s;
  transition: transform 1s;
}

.flip {
  transform: rotate(-180deg);
}

.form-description {
  color: var(--dark-grey);
  margin: 15px auto;
  padding-left: 0.313rem;
}

.form-field-description {
  font-size: 0.688rem;
  color: var(--darker-grey);
  margin: 0 auto;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* The slider */
.custom-slider {
  .rc-slider-rail {
    background-color: #e9e9e9;
    height: 2px;
    border-radius: 4px;
    top: 45%;
  }
  .rc-slider-track {
    background-color: var(--primary);
    height: 2px;
    top: 45%;
  }
  .rc-slider-handle {
    border-color: var(--primary);
    background-color: var(--primary);
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: var(--primary);
    box-shadow: 0 0 0 3px var(--primary);
  }
  .rc-slider-handle-click-focused:focus {
    border-color: var(--primary);
  }
  .rc-slider-handle:hover {
    border-color: var(--primary);
  }
  .rc-slider-handle:active {
    border-color: var(--primary);
    box-shadow: 0 0 3px var(--primary);
  }
  .rc-slider-dot-active {
    border-color: var(--primary);
  }
}

.information {
  background: #F8F9FC81;
  border: 1px solid #EBEBEB;

  .info-key {
    color: #797979;
    font-weight: 400;
  }
  .info-value {
    color: var(--darker-grey);
  }
}

@media (min-width: 992px) {
  .form-confirmation {
    text-align: right;
    margin: 0;
  }
}
