// Leaflet Sidebar styles
.custom-sidebar {
  .sidebar {
    border-radius: 10px;
    bottom: 50%;
    max-width: 300px;
    overflow: visible;
    z-index: 500;
    transition: all 0.6s ease-in-out 0s;
    opacity: 1;
  }
  .sidebar.leaflet-touch {
    box-shadow: 0px 3px 6px #00000029;
    border: 0 none;
    height: 350px;
  }
  .sidebar-right {
    right: 25px;
  }
  .sidebar-right .sidebar-header {
    padding: 0;
  }
  .sidebar-right .sidebar-content {
    right: 0;
    transition: all 0.6s ease-in-out 0s;
    transition-delay: 0.1s;
    opacity: 1;
  }
  .sidebar-right .sidebar-tabs {
    right: 310px;
    bottom: auto;
    height: 92px;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.7s ease-in-out 0s;
    transition-delay: 0.1s;
  }
  .sidebar-content {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    overflow: hidden;
  }
  .sidebar-header {
    background-color: var(--primary);
    border-top-left-radius: 10px;
    color: #f8f4f4;
    text-align: center;
    font: 600 0.8rem/47px "Open Sans";
    height: 46px;
    min-width: auto;
    margin-bottom: 1.75rem;
  }
  .sidebar-pane {
    min-width: auto;
    padding: 10px;
  }

  .sidebar-tabs,
  .sidebar-tabs > ul {
    width: 46px;
  }

  .white {
    fill: #ffffff;
  }

  .sidebar-tabs > li,
  .sidebar-tabs > ul > li {
    height: 46px;
  }
  .sidebar-tabs {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
  }
  .sidebar-tabs > li {
    background-color: $white;
  }

  .sidebar-tabs > li,
  .sidebar-tabs > ul > li {
    .ci-primary {
      fill: var(--primary);
    }
  }

  .sidebar-tabs > li:first-child,
  .sidebar-tabs > ul > li:first-child {
    border-bottom: 1px solid #d8dbe0;
  }

  .sidebar-tabs > li.active,
  .sidebar-tabs > ul > li.active {
    background-color: var(--primary);

    .ci-primary {
      fill: $white;
    }
  }
  .sidebar-right .sidebar-close {
    left: auto;
    right: 0;
    width: 46px;
    height: 46px;
  }
  .sidebar.collapsed {
    width: 0;
  }
  .sidebar.collapsed .sidebar-tabs {
    right: 0;
  }
  .c-list-title {
    margin: 1.5rem auto 0.85rem;
  }
  .c-list-title-img-box {
    display: inline-block;
    width: 62px;
    text-align: center;
    margin-right: -0.8rem;
  }
  .c-list-title-img {
    display: inline-block;
    display: inline-block;
    color: var(--dark-grey);
    width: 1.25rem !important;
    height: 1.25rem !important;
    font-size: 1.25rem !important;
    vertical-align: bottom;
  }
  .c-list-title-text {
    display: inline-block;
    font: normal normal 600 14px/18px "Open Sans";
    color: #070000;
  }
  .sidebar-form-control {
    padding-bottom: 1.25rem;
    padding-left: 1.5rem;
    border-bottom: 1px solid #ccc;

    .form-check-label {
      margin-left: 4px;
      font: 400 0.8rem/1.2rem "Open Sans";
      color: var(--dark-grey);
    }
    .custom-control-label {
      font: 400 0.8rem/1.2rem "Open Sans";
      color: var(--dark-grey);
    }
    &:last-child {
      border-bottom: 0 none;
    }
  }
}

// Sidebar Form
.sidebar-form {
    box-shadow: 0px 3px 6px #00000029;
    right: 25px;
    border-radius: 10px;
    top: 45px;
    width: 300px;
    overflow: visible;
    z-index: 500;
    position: absolute;
    background: rgba(255, 255, 255, 1);
  
    .sidebar-form-header {
      background-color: var(--primary);
      border-radius: 10px 10px 0 0;
      color: #f8f4f4;
      text-align: center;
      font: 600 0.8rem/47px "Open Sans";
      height: 46px;
    }
  
    .sidebar-form-content {
      padding: 15px 25px 10px;
  
      .form-title {
        margin: 0 0 10px;
      }
    }
  }

@media (max-width: 768px) {
  .custom-sidebar {
    .sidebar {
      top: 10px;
    }
  }
}

@media (max-width: 615px) {
  .sidebar-form {
    width: auto;
    max-width: 300px;
    top: 65px;
  }
}


@media (max-width: 450px) {
  .custom-sidebar {
    .sidebar {
      right: 25px;
      max-width: calc(100% - 150px);
    }
    .sidebar-right .sidebar-tabs {
      right: calc(100% + 10px);
    }
  }

  .sidebar-form {
    width: calc(100% - 95px);
    top: 80px;
  }
}
