// Tabs

.tabs {
    margin: 18px;

    .nav-tabs {
        border-bottom: 2px solid;
        border-color: #DDD;

        .nav-link {
            border-radius: 0;
            font-weight: 600;
            color: var(--darker-grey);
            position: relative;
            top: 2px;
            padding-left: 2px;
            padding-right: 2px;
            margin-right: 15px;
            border-width: 0 0 4px 0;
 
            &:hover,
            &:focus,
            &:active {
               outline: 0;
               border-color: transparent;
            }

            &.active {
                background-color: transparent;
                border-color: transparent;
                border-bottom: 4px solid #ED1B24;
                border-width: 0 0 4px 0;
            }
        }
    }

    .tab-content {
        margin-top: 18px;
    }
}