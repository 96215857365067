// Login

.login-logo {
    height: 70px;
}

.login-form {
  max-width: 400px;
  box-shadow: 0px 2px 15px #00000029;
  border: 1px solid #EBEBEB;
  color: var(--grey);
  .card-body {
    margin: auto 1.75rem;
    padding-bottom: 0.313rem;
  }
}

.login-title {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: var(--darker-grey);
    margin-bottom: 1.75rem;
    text-align: center;
}

.login-description {
    font-size: 0.75rem;
    line-height: 0.938rem;
    text-align: center;
}

.login-horizontal-separator {
  height: 1px;
  background-color: #EEEAEA;
  margin: 2.125rem auto 1.5rem;
}

.form-control-password {
  .form-control {
    border-right-color: transparent;
    &:focus,
    &:hover,
    &:active {
      border-right-color: transparent;
    }
    &.is-invalid {
      border-right-color: var(--danger);
      &:focus,
      &:hover,
      &:active {
        border-right-color: var(--danger);
      }
    }
  }
}

.forgot-password-link {
  font-weight: 600;
  font-size: 0.625rem;
  display: block;
  line-height: 0.813rem;
  margin: 0.5rem auto 1rem;
  color: var(--grey);
  &:hover,
  &:active,
  &:focus {
    color: var(--grey);
  }
}

.form-control-password {
    display: flex;
    background-color: #F7F8FA;
    border-radius: 4px;
    .form-control {
        margin: 0;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.border-input {
 border: 1px solid #DEDBDB;
}

.show-password-icon {
	margin: auto 0.25rem;
	color: #6c7d8b;
	outline: none;
	margin: 0;
	padding: 0 0.5rem;
	border-radius: 0 4px 4px 0;
	border-left: 0 none;
}

.login-copyright {
  text-align: center;
  font-size: 0.625rem;
  line-height: 0.813rem;
  margin-bottom: 0.5rem;
}

.login-link {
  color: var(--dark-grey);
  &:hover,
  &:active,
  &:focus {
    color: var(--dark-grey);
  }
}

.login-link-separator {
  color: var(--dark-grey);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
