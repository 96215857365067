// Buttons 

.btn-primary {
  background-color: var(--primary);
}

.btn {
  font-weight: 600;
  padding: 0.3rem 0.55rem;
  font-size: 0.75rem;

  .icon-button-sm {
    height: 0.64rem;
    width: 0.64rem;
    vertical-align: top;
    margin-top: 0.25rem;
  }
}

.btn:focus, .btn.focus {
  box-shadow: none;
}

.btn-toggle {
  color: var(--darker-grey);
  white-space: nowrap;
  &:hover {
    color: var(--darker-grey);
  }
  &:focus {
    box-shadow: none;
  }
  .toggle-icon {
    color: var(--primary);
  }
  .toggle-action {
    color: var(--dark-grey);
  }
}

.btn-transition-in {
  transition: display 0.3s ease-in-out 0s;
  opacity: 1;
  animation-name: transition-in;
  animation-duration: 0.5s;
}

.btn-light-gray {
  background-color: #EEEAEA;
  border-color: #DDDEE0;
  color: #696969;
  &:hover,
  &:focus,
  &:active {
    background-color:#ebedef;
  }
}

.font-weight-600 {
  font-weight: 600;
}

@keyframes transition-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
