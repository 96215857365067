.filter-info {
    min-height: 30px;
    margin-bottom: .1rem;
}

.filter-wrapper {
    color: var(--dark-grey);
    position: sticky;
    border: 1px solid #e2e5e5;
    background-color: #f5f7f7;
    padding: 0.15rem 0.3rem 0.15rem 0.3rem;
    margin-bottom: 1rem;
    border-radius: 1px;
    z-index: 1001;
}

.advanced-filter {
    position: absolute;
    top: 40px;
    width: 100%;
    left: 0;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.30) !important;
}

.filter-search {
    margin-top: 0.125rem;
    height: 30px;
    width: 200px;
    .search-button {
        margin: 0;
        height: 28px;
        outline: none;
        padding: 0.25rem;
        .c-icon {
            position: relative;
            top: -0.25rem;
        }
    }
    .search-input {
        color: var(--grey); 
        height: 28px;
        margin: 0;
        border: none !important;
        padding-left: 0;
        padding-right: 0.25rem;
        background-color: white !important;
          &::placeholder {
            color: var(--grey); 
        }
    }
}

.filter-toggle {
 &:focus {
    box-shadow: none;
 }
}

.sections {
    flex-direction: row;
}

.sections > div:not(:last-child) {
    border-right: 1px solid #DDDEE0;
}

@media (max-width: 768px) {
    .sections {
        flex-direction: column !important;
    }
    .sections > div {
        padding: .7rem 0;
    }
    .sections > div:not(:last-child) {
        border-right: none;
        border-bottom: 1px solid #DDDEE0 !important;
    }
}