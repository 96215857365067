// Tables

.table {
  font: normal normal normal 12px/24px "Open Sans";
  color: var(--dark-grey);
  border-color: #eeeaea;

  thead {
    th {
      border-bottom: 1px solid #eeeaea;
      font: normal normal bold 12px/24px "Open Sans";
      border-top: 0 none;
      color: #666666;
    }
  }

  th,
  td {
    vertical-align: middle;
    border-top-color: #eeeaea;
  }

  tr {
    &:last-child {
      border-bottom: 1px solid #eeeaea;
    }
  }
}

.table-sm {
  th,
  td {
    padding: 0.3rem 0.3rem 0.3rem 0.813rem;
  }
}

.table-striped {
  tbody {
    tr:nth-of-type(2n + 1) {
      background-color: #fafafb;
    }
  }
}

.table-header {
  color: #666666;
  font-weight: bold;
}

.c-datatable-items-per-page {
  label {
    white-space: nowrap;
  }
}

.table-filter-wrap > div {
  &:nth-child(1) { 
    border: 1px solid #e2e5e5;
    background-color: #f5f7f7;
    padding: 0.26rem 0.36rem 0.26rem 0;
    margin-bottom: 1rem;
    border-radius: 1px;
  }
  .my-2 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .form-inline {
    label {
      margin-left: -0.25rem;
    }
  }
}

.p-0false {
  padding: 0 !important;
}

// Devices Table Styles
.main-table {
  thead {
    th {
      border-top: 0 none;
      border-bottom: 0 none;
      color: #666666;
      padding: 0;

      &.devices-name {
        white-space: nowrap;
      }

      &.devices-width {
        width: 320px;
      }

      &.devices-time {
        width: 140px;
      }
    }
  }

  &.border-top {
    border-color: #eeeaea !important;
  }

  tbody {
    tr {
      &:last-child {
        border-bottom: 0 none !important;
      }
    }
    .collapse.show {
      tr {
        &:last-child {
          border-bottom: 0 none !important;
        }
      }
    }
    tr {
      td {
        border-top: 0 none;
        padding: 5px 4.8px;
        
        &.status-red {
          color: var(--danger);
        }
        &.status-grey {
          color: #bdbdbd;
        }
        &.status {
          color: var(--dark-grey);
        }
        &:first-child {
          padding-left: 15px;
        }
      }

      &:nth-child(2n + 1):not(.p-0) {
        background-color: #ffffff;
      }

      &:nth-child(4n + 1):not(.p-0) {
        background-color: #fafafb;
      }

      &:nth-child(4n + 1) {
        border-bottom: 1px solid #eeeaea;
      }

      &:nth-child(2n):not(.p-0) {
        background-color: #fafafb;
      }

      &:nth-child(4n):not(.p-0) {
        background-color: #ffffff;
      }

      &:nth-child(4n) {
        border-top: 1px solid #eeeaea;
      }
    }
  }
}

.table-icon {
  width: 25px;
  display: inline-block;
  text-align: right;
}

.table-icon-text {
  font-size: 0.7rem;
  display: inline-block;
  vertical-align: text-top;
}

.table-expand-link,
.table-expand-link:hover,
.table-expand-link:focus,
.table-expand-link:active {
  display: inline-block;
  color: var(--dark-grey);
  text-decoration: none;
}

.table-device-name {
  display: inline-block;
  &-bold {
    font-weight: 600;
  }
}

.gateway,
.datalogger,
.collection {
  font-weight: 600;
}

.table-device-name-link {
  color: var(--dark-grey);
  text-decoration: underline;
  &:hover,
  &:active,
  &:focus {
    color: var(--dark-grey);
  }
}

.enclosed-table {
  margin-bottom: 0;
  padding: 0;
  margin-left: 50px;
  width: calc(100% - 50px);

  thead {
    height: 0;
    visibility: unset;
    tr {
      border-bottom: 0 none !important;
    }
    th {
      padding: 0;
      border: 0 none;
    }
  }

  tbody {
    tr {
      &:last-child {
        border-bottom: 0 none !important;
      }
    }
    tr {
      td {
        border-top: 0 none;
        padding: 5px 4.8px;
        &:first-child {
          padding-left: 15px;
        }
      }

      &:nth-child(4n + 1) {
        border-bottom: 1px solid #eeeaea;
      }
      &:nth-child(4n) {
        border-top: 1px solid #eeeaea;
      }
    }
  }
}

.profile-table {
  .enclosed-table,
  .second-enclosed-table {
    margin-left: 0;
    border-top: none;
    width: 100%;
  }
  .collections-table {
    margin-left: 43px;
    width: calc(100% - 43px);

    .second-enclosed-table {
      margin-left: 43px;
      width: calc(100% - 43px);
    }
  }
  &.second-enclosed-table {
    margin-left: 0;
    border-top: none;
    width: 100%;
  }
  .table-device-name-bold {
    font-weight: 400;
  }
}

@mixin background-colors($light: true) {
  @if $light {
    background-color: $light-background;
  } @else {
    background-color: $dark-background;
  }
}

.main-table tbody tr .enclosed-table tbody tr {
  &:nth-child(2n+1):not(.p-0) {
    @include background-colors($light: false);
  }
  &:nth-child(4n+1):not(.p-0) {
    @include background-colors($light: true);
  }
}

.main-table tbody tr:nth-child(4n) .enclosed-table tbody tr {
  &:nth-child(2n+1):not(.p-0) {
    @include background-colors($light: true);
  }
  &:nth-child(4n + 1):not(.p-0) {
    @include background-colors($light: false);
  }
}

.second-enclosed-table {
  margin-bottom: 0;
  margin-left: 43px;
  width: calc(100% - 43px);

  thead {
    th {
      &.devices-time {
        width: 321px;
      }
    }
  }

  tbody {
    tr {
      &:last-child {
        border-bottom: 0 none !important;
      }
    }
    tr {
      background-color: #ffffff !important;
      td {
        border-top: 0 none;
      }
      &:nth-child(4n + 1) {
        border-bottom: 1px solid #eeeaea;
      }
      &:nth-child(4n) {
        border-top: 1px solid #eeeaea;
      }
    }
  }
  .second-enclosed-table {
    border-bottom: 1px solid #eeeaea;
  }

  &.borders {
   border-bottom: 1px solid #eeeaea;
    tr {
      border-bottom: 1px solid #eeeaea;
    }
  }
}

.dataTables_wrapper {
  .dataTables_paginate {
    .paginate_button {
      padding: 0;
      background: $white;
    }
  }
}

@media (max-width: 1128px) {
  .main-table {
    font-size: 11px;
    line-height: 1.4;

    thead {
      th {
        &.devices-width {
          width: 100px;
        }

        &.devices-time {
          width: 60px;
        }
      }
    }
  }

  .collections-table {
    margin-left: 10px !important;
    width: calc(100% - 10px) !important;

    .second-enclosed-table {
      margin-left: 10px !important;
      width: calc(100% - 10px) !important;
    }
  }

  .enclosed-table {
    font-size: 11px;
    line-height: 1.4;
    margin-left: 10px;
    width: calc(100% - 10px);
  }

  .second-enclosed-table {
    font-size: 11px;
    line-height: 1.4;
    margin-left: 10px;
    width: calc(100% - 10px);

    thead {
      th {
        &.devices-width {
          width: 100px;
        }
        &.devices-time {
          width: 241px;
        }
      }
    }
  }
}

@media (min-width: 576px) {
.table-advanced-filter-wrap > div {
  &:nth-child(2) {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1002;
    width: 360px;
  }
  .my-2 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}
}

@media (max-width: 575px) {
  .table-filter-wrap > div:nth-child(1) {
    padding: 0 0.25rem 0.25rem;

    label {
      margin: 0.5rem 0.25rem 0.25rem;
    }
  }
}

@media (max-width: 450px) {
  .p-0false {
    margin-top: 0.5rem;
  }
}

@media (min-width: 450px) {
  .table-responsive {
    overflow: unset;
  }
}
