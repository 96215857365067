// Variable overrides

$theme-colors: (
  primary:#4DA8CB,
  secondary:#7D8184,
  warning: #E39C21,
  scarlet: #ed1b24,
  light-grey: #F9F9F9,
  grey:#666666,
  dark-grey: #575757,
  darker-grey: #000505,
  light: #ffffff,
);

$body-bg: #f4f7fc;
$body-color: var(--grey);
$font-size-base: 0.75rem;
$table-color: var(--dark-grey);
$light-background: #ffffff;
$dark-background:  #fafafb;
$reduced-spacing: 0.5rem;
$black: #000000;
$white: #ffffff;
$icon-size-base: 1rem;

