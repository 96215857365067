// Custom CORE UI styles
.dropdown-menu.show.adjust { top: -10px !important; right: -50px !important }

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

*:focus {
  outline: none !important;
}

// General
body {
  font-family: "Open Sans", var(--font-family-sans-serif);
}

.c-app {
  height: 100vh;
  overflow: hidden;
  background-color: #F9F9F9;
}

.c-body { 
  height: calc(100% - 106px);
}

.c-wrapper {
  margin-top: 55px;
  min-height: calc(100vh - 55px);
}

.c-main {
  padding-top: 0;
  height: calc(100% - 106px);
  overflow-y: auto;
  overflow-x:hidden;
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

.p-reduced {
  padding: $reduced-spacing;
}

.c-header.c-header-fixed {
  z-index: 1032;
  box-shadow: 0 3px 4px 0 rgba(60, 75, 100, 0.14);
}

.logo {
  height: 35px;
  margin-left: 0.25rem;
}

.logo-min {
  height: auto;
  width: 50px;
}

.logo-wrap {
  background-color: $white;
}

// Layout
.c-subheader {
  color: var(--grey);
  .breadcrumb-item,
  .breadcrumb {
    &.active,
    &:before {
      color: var(--grey);
      font-weight: 600;
    }

    a {
      color: var(--grey);
      &.active,
      &:hover,
      &:focus,
      &:active {
        color: var(--grey);
      }
    }
  }  

  .breadcrumb:last-child li {
    &:last-child {
      font-weight: 600;
    }
  }
}

.dropdown-menu {
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.30);
}

.pagination {
  margin-bottom: 0;
  margin-top: 1rem;
}

// Core UI flyout sidebar
.c-sidebar.c-sidebar-fixed {
  top: 56px;
}

.c-sidebar.c-sidebar-light {
  background-color: var(--light-grey);
}

.c-sidebar-nav-item {
  font: normal normal normal 0.813rem/1.125rem "Open Sans", var(--font-family-sans-serif);
  color: var(--grey);
}

.c-sidebar-nav {
  padding-top: $reduced-spacing; 
}

.c-sidebar.c-sidebar-light.c-sidebar-minimized {
  .c-sidebar-nav-link {
    z-index: 1031;
    .c-sidebar-nav-icon {
      flex-basis: 53px;
    }

    &.c-active {
      background-color: #ECECEC;
      .c-icon {
        color: var(--primary);
      }
    }
  }
}

.c-sidebar.c-sidebar-light:not(.c-sidebar-minimized) {
  .c-sidebar-nav {
    padding-top: $reduced-spacing * 3; 
  }
  .c-sidebar-nav-title {
    padding: 0.5rem 1rem;
    margin-top: 1.45rem;
  }
  .c-sidebar-nav-link {
    padding: 0.44rem 1rem;
    .c-sidebar-nav-icon {
      flex-basis: 20px;
      margin-left: -2px;
      margin-right: 5px;
    }
  }
}

.c-sidebar.c-sidebar-light {
  .c-sidebar-nav-title {
    font: normal normal bold 0.688rem/0.938rem "Open Sans", var(--font-family-sans-serif);
    color:  #7D8184;;
  }

  .c-sidebar-nav-link,
  .c-sidebar-nav-dropdown-toggle {
    color: var(--grey);
    padding: 0.85rem;
    &:hover {
      color: var(--primary);
      background-color: #ECECEC;
      .c-sidebar-nav-icon {
        color: var(--primary);
      }
    }
  }

  .c-sidebar-nav-link {
    .c-sidebar-nav-icon {
      color: var(--grey);
      height: 1.25rem;
    }
  }

  .c-active.c-sidebar-nav-dropdown-toggle {
    .c-sidebar-nav-icon {
      color: var(--primary);
      background-color: transparent;
    }
  
    color: var(--grey);
  
    &:hover {
      color: var(--primary);
      background-color: #ECECEC;
      .c-sidebar-nav-icon {
        color: var(--primary);
      }
    }
  }

  .c-sidebar-nav-link.c-active {
    color: var(--primary);
    font-weight: 600;
    background-color: transparent;
  
    &:hover {
      color: var(--primary);
      background-color: #ECECEC;
  
      .c-sidebar-nav-icon {
        color: var(--primary);
      }
    }
  
    .c-sidebar-nav-icon {
      color: var(--primary);
      background-color: transparent;
    }
  }
    
  .c-sidebar-minimizer {
    background-color: #DEDFE5;
    border-right: 1px solid rgba(159, 167, 179, 0.5);
    &:hover {
      background-color: #DEDFE5;
      border-right: 1px solid rgba(159, 167, 179, 0.5);
    }
    &:before {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2B2B2C' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
      background-size: 9px;
      &:hover {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2B2B2C' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
        background-size: 9px;
      }
    }
  }
}

.mw-100px {
  max-width: 100px;
}

.mw-none {
  max-width: none;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  z-index: 2100;
  border-radius: 0;

  .dropdown-header {
    color: var(--dark-grey);
  }

  .dropdown-item {
    color: var(--grey);
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;

    &:hover,
    &:focus,
    &:active {
      color: var(--darker-grey);
      background-color: #ECECEC;
    }

    &.active {
      color: var(--primary);
      background-color: #ECECEC;
      font-weight: 600;

      &:hover,
      &:focus,
      &:active {
        color: var(--primary);
        background-color: #ECECEC;
      }
    }
  }
}

.message-box { 
  height: 40px;
  position: absolute;
  z-index: 1001;
  top: - 44px;
  left: .25%;
  width: 99.5% !important;
  cursor: grabbing;
  overflow-y: auto;
}

.top-panel {
  border: 1px solid #e2e5e5;
  background-color: #f5f7f7;
  position: relative;
  padding: 0.25rem 0.3rem 0.25rem;
  margin-bottom: $reduced-spacing;
  border-radius: 1px;
  height: 40px;
}

.page-item {
  margin: 0 0.125rem;
  &:first-child {
    margin-left: 0;
    margin-right: 0.25rem;
  }
  &:last-child {
    margin-right: 0;
    margin-left: 0.25rem;
  }
}

.page-link {
  color: #444444;
  font-weight: bold;
  line-height: 0.938rem;
  padding: 0.313rem 0.625rem;
  border-color: #DADDE1;
  border-radius: 0 !important;
}


// Components

.gateway-guid {
  max-width: 410px;
}

.text-sm {
  color: var(--dark-grey);
}

@media (min-width: 992px) {
    html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper, 
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper {
    margin-left: 49px;
  }

  //Override for Nav with tooltip
  .c-sidebar-minimized .c-sidebar-nav-link {
    &:hover,
    &:active,
    &:focus {
      width: 51px;
      background: rgba(0, 0, 0, 0.05) !important;
      color: var(--primary) !important;
    }
  }

  .c-sidebar-minimized.c-sidebar-fixed {
    width: 51px;
  }

}
