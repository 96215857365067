// Icons 
.icon {
  width: $icon-size-base;
  height: $icon-size-base;
  font-size: $icon-size-base;
  
  &-xs {
    width: $icon-size-base * 0.6;
    height: $icon-size-base * 0.6;
    font-size: $icon-size-base * 0.6;
  }

  &-sm {
    width: $icon-size-base * 0.75;
    height: $icon-size-base * 0.75;
    font-size: $icon-size-base * 0.75;
  }

  &-md {
    width: $icon-size-base * 1.125;
    height: $icon-size-base * 1.125;
    font-size: $icon-size-base * 1.125;
  }

  &-lg {
    width: $icon-size-base * 1.5;
    height: $icon-size-base * 1.5;
    font-size: $icon-size-base * 1.5;
  }

  &-xl {
    width: $icon-size-base * 1.75;
    height: $icon-size-base * 1.75;
    font-size: $icon-size-base * 1.75;
  }

}

.c-avatar {
  height: 28px;
  width: 28px;
  .c-avatar-img {
    color: var(--grey);
    &:hover,
    &:active,
    &:focus {
      color: var(--primary);
    }
  }
}

.instruments-active {
  width: 16px;
  margin-right: 4px;
  margin-bottom: 1px;
}

.instruments-inactive {
  width: 12px;
  margin-right: 5px;
  margin-left: 2px;
  margin-bottom: 1px;
}

.icon-battery-low {
  height: 12px;
}
